import React from "react";

const Checkbox = (props) => {
  return (
    <div className="callback-checkbox__container">
      <label className="callback-checkbox__label" htmlFor={props.name}>
        {props.name}
      </label>
      <input
        className="callback-checkbox__input"
        type="checkbox"
        name=""
        id={props.name}
        defaultChecked
      />
    </div>
  );
};

export default Checkbox;
