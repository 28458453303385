import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__left">
        <span>&copy; 2020 - Lumens by Lukas Meier</span>
      </div>
      <div className="Footer__right">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Juggern4ut/LumensSlider2"
        >
          GitHub
        </a>
      </div>
    </footer>
  );
};

export default Footer;
