import React from "react";
import Card from "./Components/Card/Card";
import Header from "./Components/Header/Header";
import Slideshow from "./Components/Slideshow/Slideshow";
import text from "./res/texts";
import Checkboxes from "./Components/Checkboxes/Checkboxes";
import ImageSlide from "./Components/ImageSlide/ImageSlide";
import Footer from "./Components/Footer/Footer";

class App extends React.Component {
  state = {
    callback: [],
    option: [],
    property: [],
    responsiveObject: [],
  };

  async componentDidMount() {
    let property = await fetch(
      "https://lumens-2.firebaseio.com/properties.json"
    );
    property = await property.json();

    let option = await fetch("https://lumens-2.firebaseio.com/options.json");
    option = await option.json();

    let callback = await fetch(
      "https://lumens-2.firebaseio.com/callbacks.json"
    );
    callback = await callback.json();

    let responsiveObject = await fetch(
      "https://lumens-2.firebaseio.com/responsiveOptions.json"
    );
    responsiveObject = await responsiveObject.json();

    this.setState({
      callback,
      option,
      property,
      responsiveObject,
    });
  }

  render() {
    const properties = this.state.property.map((prop, index) => {
      return (
        <Card
          key={index}
          title={prop.name}
          type={prop.type}
          description={prop.description}
          color="property"
        />
      );
    });

    const option = this.state.option.map((prop, index) => {
      return (
        <Card
          key={index}
          title={prop.name}
          type={prop.type}
          description={prop.description}
          color="option"
        />
      );
    });

    const responsiveOption = this.state.responsiveObject.map((prop, index) => {
      return (
        <Card
          key={index}
          title={prop.name}
          type={prop.type}
          description={prop.description}
          color="responsiveObject"
        />
      );
    });

    const callback = this.state.callback.map((prop, index) => {
      return (
        <Card
          key={index}
          title={prop.name}
          type={prop.type}
          description={prop.description}
          color="callback"
        />
      );
    });

    return (
      <div>
        <Header
          words={[
            "configurable",
            "fast",
            "clean",
            "vanilla",
            "tiny",
            "compatible",
            "responsive",
            "lightweight",
          ]}
        />
        <main className="main">
          <section className="section">
            <h2 className="section-title">{text.welcomeTitle}</h2>
            <h3>{text.welcomeSubtitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: text.welcomeText }}></div>
          </section>

          <section className="section">
            <h2 className="section-title">Usage</h2>

            <p>
              After including the JavaScript-Class into your project, the usage
              of the slider is as simple as it gets.
            </p>
            <p>First setup your DOM</p>
            <pre class="code">
              <span class="tag-bracket">&lt;</span>
              <span class="tag">div</span> <span class="keyword">class</span>=
              <span class="value">'mySlideshow'</span>
              <span class="tag-bracket">&gt;</span>
              <br />
              &nbsp;&nbsp;<span class="tag-bracket">&lt;</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>Slide 1
              <span class="tag-bracket">&lt;/</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>
              <br />
              &nbsp;&nbsp;<span class="tag-bracket">&lt;</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>Slide 2
              <span class="tag-bracket">&lt;/</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>
              <br />
              &nbsp;&nbsp;<span class="tag-bracket">&lt;</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>Slide 3
              <span class="tag-bracket">&lt;/</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>
              <br />
              <span class="tag-bracket">&lt;/</span>
              <span class="tag">div</span>
              <span class="tag-bracket">&gt;</span>
            </pre>

            <p>
              Now all you need to do, is create a new instance of a Lumens
              slider and pass the selector on which you want to create the
              slideshow.
            </p>
            <pre class="code">
              <span class="tag">var </span>
              <span class="keyword">slideshow</span> =
              <span class="tag"> new</span>
              <span class="class"> Lumens</span>(
              <span class="value">".mySlideshow"</span>);
            </pre>

            <p>
              If you want to configure your slideshow, you cann set the options
              you want do manipulate in an object as the second parameter.
            </p>
            <pre class="code">
              <span class="tag">var </span>
              <span class="keyword">slideshow</span> =
              <span class="tag"> new</span>
              <span class="class"> Lumens</span>(
              <span class="value">".mySlideshow"</span>, {"{"}
              <br />
              &nbsp;&nbsp;<span class="keyword">slidesPerPage</span>:
              <span class="optionvalue"> 3</span>
              <br />
              {"}"}
            </pre>
          </section>

          <section className="section">
            <h2 className="section-title">Demos</h2>

            <h3 className="section-subtitle">Default slider</h3>
            <p>
              This is the default slider that gets created when no additional
              options are passed.
            </p>
            <Slideshow slideCount="5" slideshowclass="default-slider" />

            <h3 className="section-subtitle">Responsive slider</h3>
            <p>
              This slider will change it's behaviour based on the screen width.
              If your device is wider than 1000 pixels, you will see two slides
              per page, otherwise the slideshow will render only one slide per
              page.
            </p>
            <Slideshow slideCount="5" slideshowclass="responsive-slider" />

            <h3 className="section-subtitle">Infinite slider</h3>
            <p>
              Setting Options.loop to true, will make the slideshow scroll
              indefinitly by wrapping back to the beginning at the end.
            </p>
            <Slideshow slideCount="5" slideshowclass="infinite-slider" />

            <h3 className="section-subtitle">Thumbnail slider</h3>
            <p>
              Using very basic JavaScript and some properties and methods
              provided by Lumens, you can easily create a slideshow using
              clickable thumbnails.
            </p>
            <div className="thumbnail-slider-large">
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="500"
                imageHeight="350"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="400"
                imageHeight="350"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="800"
                imageHeight="450"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="400"
                imageHeight="550"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="400"
                imageHeight="450"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-large__slide"
                imageWidth="600"
                imageHeight="550"
                altText="Kitten"
              />
            </div>

            <div className="thumbnail-slider-small">
              <ImageSlide
                slideClass="thumbnail-slider-small__slide thumbnail-slider-small__slide--active"
                imageWidth="500"
                imageHeight="350"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-small__slide"
                imageWidth="400"
                imageHeight="350"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-small__slide"
                imageWidth="800"
                imageHeight="450"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-small__slide"
                imageWidth="400"
                imageHeight="550"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-small__slide"
                imageWidth="400"
                imageHeight="450"
                altText="Kitten"
              />
              <ImageSlide
                slideClass="thumbnail-slider-small__slide"
                imageWidth="600"
                imageHeight="550"
                altText="Kitten"
              />
            </div>
          </section>

          <section className="section">
            <h2 className="section-title">Callbacks</h2>
            <p>
              In this little demonstration you can see all the callbacks the
              slider provides. If you change the slides you can see what
              callbacks get triggered.
              <br />
              <br />
              Use the checkboxes to decide what callbacks should be watched.
            </p>

            <Checkboxes />

            <Slideshow slideCount="5" slideshowclass="callback-slider" />
            <textarea readOnly id="callback-log"></textarea>
          </section>

          <section className="section">
            <h2 className="section-title">Download</h2>
            <article
              dangerouslySetInnerHTML={{ __html: text.downloadText }}
            ></article>
          </section>

          <section className="section">
            <h2 className="section-title">Documentation</h2>
            <h3 className="section-subtitle">Properties</h3>
            {properties}
            <h3 className="section-subtitle">Options</h3>
            {option}
            <h3 className="section-subtitle">ResponsiveObject</h3>
            {responsiveOption}
            <h3 className="section-subtitle">Callbacks</h3>
            {callback}
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;
