import React from "react";
import Checkbox from "./Checkbox";
import "./Checkboxes.css";

const Checkboxes = (props) => {
  return (
    <div className="callback-checkbox">
      <Checkbox name="onDragging" />
      <Checkbox name="onStopDragging" />
      <Checkbox name="onAnimating" />
      <Checkbox name="onFinishAnimating" />
      <Checkbox name="onDestroy" />
      <Checkbox name="onInit" />
      <Checkbox name="onChangeResponsive" />
      <Checkbox name="onSlideChange" />
      <Checkbox name="onSlideChanged" />
      <Checkbox name="onLoop" />
    </div>
  );
};

export default Checkboxes;
