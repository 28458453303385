import React from "react";

const ImageSlide = (props) => {
  return (
    <div className={props.slideClass}>
      <img
        onDragStart={(e) => {
          e.preventDefault();
          return false;
        }}
        alt={props.altText}
        src={
          "https://placekitten.com/" +
          props.imageWidth +
          "/" +
          props.imageHeight
        }
      />
    </div>
  );
};

export default ImageSlide;
