import React from "react";
import "./Card.css"

const Card = (props) => {

  const cardClass = "Card Card--"+props.color;

  return (
    <article className={cardClass}>
      <p className="Card__title">{props.title}</p>
      <span className="Card__type">{props.type}</span>
      <p className="Card__description">{props.description}</p>
    </article>
  );
};

export default Card;
