import React from "react";
import "./Slideshow.css";

const Slideshow = (props) => {
  let slides = [];
  for (let i = 0; i < props.slideCount; i++) {
    slides.push(
      <div key={i} className={props.slideshowclass + "__slide"}>
        <p className={props.slideshowclass + "__content"}>Slide {i + 1}</p>
      </div>
    );
  }

  return <div className={props.slideshowclass}>{slides}</div>;
};

export default Slideshow;
