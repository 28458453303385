import React from "react";
import "./Header.css";
import bulb from "../../res/img/bulb.svg";

const Header = (props) => {
  let slides = props.words.map((word, index) => {
    return (
      <div key={index} className="Header__slide">
        <p className="Header__slide-title">{word}</p>
      </div>
    );
  });

  return (
    <header className="Header">
      <img className="Header__bulb" alt="Lumens Logo" src={bulb} />
      <h1 className="Header__title">Lumens II</h1>
      <span className="Header__title-span">it's</span>
      <section className="Header__slider">{slides}</section>
    </header>
  );
};

export default Header;
